module.exports = new Promise(resolve => {
					try{
						const urlParams = new URLSearchParams(window.location.search)
						// This part depends on how you plan on hosting and versioning your federated modules
						const remoteUrlWithVersion = '/topic-builder' + '/remoteEntry.js'
						const script = document.createElement('script')
						script.src = remoteUrlWithVersion
						script.onload = () => {
						// the injected script has loaded and is available on window
						// we can now resolve this Promise
						const proxy = {
							get: (request) => window.builder.get(request),
							init: (arg) => {
							try {
								return window.builder.init(arg)
							} catch(e) {
								console.log('remote container already initialized')
							}
							}
						}
						resolve(proxy)
						}
						script.onerror = () => {
							const module = {
								get: () => () => {},
								init: () => () => {},
							  };
							resolve(module);
						};
						// inject this script with the src set to the versioned remoteEntry.js
						document.head.appendChild(script);
					}catch(err){
						console.log("error")
					}
				  })
				  ;